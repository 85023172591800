.header-nav {
    margin: 0 auto;
    max-width: 1070px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #aaa;
    z-index: 3;
    padding-left: 5px;
    padding-right: 5px;
}

.header-nav div:nth-child(1) img {
    max-width: 120px;
  }

.header-nav div:nth-child(1) {
    padding: 10px 0px;
    font-size: 14px;
    display: flex;
    align-items: flex-end;
  }

.header-hamburger-meny {
    padding: 10px 0px;
    display: flex;
    align-items: flex-end;
    color: #aaa;
    display: none;
    font-size: 30px;
}

.navigation-links {
    display: flex;
    align-items: flex-end;
}


.menu a {
    margin-left: 20px;
    font-weight: 1000;
  }