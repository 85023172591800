.terminate-subscription-card {
    width: 50%;
    margin: 0 auto;
    margin-top: 5%;
    border: none;
}

.terminate-subscription-card-title {
    font-size: 40px;
    font-weight: bold;
}

.link-underline {
    color: #1E1E40 !important;
    text-decoration: underline !important;
    
}
.warning-green {
    background-color: #002B1D;
    color: #CBE1A2;
    font-weight: bold;
    padding: 17px;
    width: 72%;
    border-radius: 15px;
}

.warning-green a {
    color: #E7D0FF;
}
#warning-message-0, #warning-message-3, #warning-message-2 {
    display: none;
}

@media only screen and (max-width: 800px) {
    .terminate-subscription-card {
        width: 90%;
    }
}
