.login-form-container {
  max-width: 800px;
  justify-content: center;
  margin-top: 40px;
}

.login-form {
  max-width: 400px;
  justify-content: center;

  margin: 0 auto;
}

.title {
  text-align: center;
}

.fabel-alert {
  max-width: 405px;
  margin: 0 auto;
  line-height: 20px;
}

.alert-positive {
    color: #000;
    background-color: #fbf0f0;
    border-color: #ffcece;
}


