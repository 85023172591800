.VippsSucFabelLogo{
  max-width: 250px;
  margin-top: 50px;
}
.vippssuccessmessage{
  font-size: 24px;
}
.vippSucVipLogo{
  max-width: 200px;
}
.txtAlignCenter{
  text-align: center;
}