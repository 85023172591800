.receipt {
    margin: 0 auto;
    width:50%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.title-receipt {
  font-family: Sofia Pro Regular;
  font-weight: bold;
}
.receipt-logo{
  width: 150px;
}
.text-align-right{
  text-align: right;
}
.gap{
  margin-top: 50px;
}
.gap-20{
  margin-top: 20px;
}
.text-bold{
  font-weight: bold;
}
.row {
  font-size: 14px;
}
.mobile-only{
    display: none;
  }
   .receiptList div{
    border-bottom: 1px solid black;
  }
  .recieptHeads{
    background-color: #EEEEEE;
    font-weight: bold;
  }
  .viewReceiptLink{
    cursor: pointer;
    border: none !important;
  }
@media only screen and (max-width: 600px) {
  .receipt {
      width: 94%;
  }
  .title-receipt {
      margin-left: 10px;;
  }

  .desktop-only{
    display: none;
  }

  .mobile-only{
    display: block;
  }
  .mobile-font {
    font-size: 16px;
    font-weight: bold;
  }
  
}