body {
  margin: 0 0 100px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-style: normal;
  font-weight: 100;
}

html {
  position: relative;
  min-height: 100%;
}

.App {
  margin: 0 0 200px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.btn-link {
  color: #1e1e40 !important;
  text-decoration: none !important;
}

.bold-text {
  font-weight: 1200;
}

.new_prod_container {
  margin-top: 20px;
  border: 1px 0px 1px 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0px !important;
}
.new_prod {
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 100%;
  margin: 10px 0px 10px 0px;
}
.list_group_title {
  color: #1e1e40;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.disclaimer-text {
  color: grey;
  font-size: 13px;
  width: 90%;
}
.fabel-text {
  color: #1e1e40;
  font-weight: 600;
}
.modal-header .btn-close {
  display: none;
}
.drp-icon {
  color: #1e1e40;
  font-size: 16px;
}
.exbold-text {
  color: #1e1e40;
  font-family: Filson Pro Heavy;
}
.user-product-info {
  display: flex;
  flex-direction: row;
  font-weight: 1000;
}
.rnw-btn button {
  margin-top: 15px;
  background-color: #1e1e40;
  border: none;
}
.rnw-btn button:hover {
  background-color: #30304b;
}
.rnw-box {
  padding: 20px;
}
.card-col {
  width: 100%;
  padding-top: 10px;
}
.card-col a {
  margin-left: 20px;
}
.card-col .btn {
  background-color: #1e1e40;
  border: none;
  color: white;
}
.card-col .btn:hover {
  color: white;
  background-color: #3b3b5e;
}
.product-display {
  display: grid !important;
  grid-template-columns: 50px auto;
}
.purple-campaign {
  background-color: #46387f;
  color: #fff;
}
.product-radio {
  grid-column: 1/2;
}
.product-radio span {
  position: relative;
  top: 20%;
}
.product-info {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}
.user-product-info .exbold-text {
  font-size: 14px;
}
/*.product-name p{
  width: max-content;  
}*/
.text-grey {
  color: grey;
  opacity: 0.7;
}
@media only screen and (max-width: 720px) {
  .d-flex {
    flex-direction: column !important;
  }
  .card-col a {
    margin: 0 auto;
  }
  .user-product-info {
    display: flex;
    flex-direction: column;
  }
  .user-product-info span {
    display: none;
  }
  .radio-btn {
    position: relative;
    top: -25px;
  }
  .product-name {
    width: auto;
  }
}
