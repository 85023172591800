.password-form-container {
    max-width: 500px;
    justify-content: center;
    margin-top: 80px;
    border: none;
}

.password-form {
    max-width: 500px;
    justify-content: center;
    display: block;
    margin: 0 auto;
    padding-top: 50px;
  }
  
  .title-card {
    text-align: center;
    border: none;
    margin-bottom: 40px;
}

.link-btn {
    padding-left: 0;
    margin-top: 20px;
}

.login-btn, .confirm-pass-btn {
    margin-top: 20px;
    width:100%;
}

.card-text {
    text-align: start;
  }

.smsinput {
    width: 100%;
    display:inline;
}

.password-input {
    padding-top: 10px;
    width: 100%;
    margin: 0 auto;
}
