.mypage {
    margin: 0 auto;
    width:50%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.stopped-container{
	background-color: #46387f;
	text-align: center;
}
.stopped-user-product {
	background-color: #46387f;
}
.stopped-heading {
  font-weight: bold;
  font-size: 16px;
  color: #e7d0ff;
}
.stopped-tagline{
  color: #cbe1a2;
}
.stopped-4001-text{
	color: #FFFFFF;
	font-weight: normal;
	margin-top: -10px;
}
.stopped-4001-name{
	font-size: 16px;
  	color: #e7d0ff;
}
.note-for-101-201{
background-color: #46387f;color: #FFF;padding: 10px;border-radius: 5px;
}
@media only screen and (max-width: 600px) {
.mypage {
    width: 100%;
}
.title-mypage {
    margin-left: 10px;;
}
}