.card-main {
  width: 700px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.card-body {
  width: 100%;
  padding: fit-content;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.card-text {
  margin-left: 3%;
}

.expand-btn {
  float: right;
  background-color: white;
  border: none;
  font-size: 20px;
}

.input-group {
  padding-top: 2px;
}

.confirm-password-btn {
  margin-left: auto;
}

.list-grp {
  margin-bottom: 0;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  padding-top: 20%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.book-text {
  padding-left: 0;
  font-size: 20px;
}

.book-thumbnail {
  max-width: 75%;
}

.btn-see-all-books {
  min-width: 100px;
  font-size: 25px;
  text-align: end;
}

.purchased-books-controller {
  width: 50%;
  margin: 0 auto;
  margin-bottom: 10px;
  border: none;
  margin-top: 15px;
}

.card-row {
  padding-bottom: 5px;
  padding-top: 5px;
  width: 80%;
  margin: 0 auto;
}

.cvc-col {
  padding-right: 0;
}

.expiration-col {
  padding-left: 0;
}

.stripeelement {
  border-radius: 4px 4px 0 0;
  padding: 15px;
  border: 1px solid rgba(50, 50, 93, 0.4);
  background: white;
}

.btn-card-confirm {
  width: 80%;
  margin: 0 auto;
}

.strex-card {
  border: none;
}

.payment-history-card {
  border: none;
}

.giftcard-card {
  border: none;
}

.payment-card {
  border: none;
}

.terminate-sub-card {
  border: none;
}

.line-text {
  display: inline;
  text-decoration: line-through;
}

.campaign-text {
  font-style: italic;
  margin-bottom: 6px;
}

.campaign-price {
  display: inline;
  color: green;
}

.line-text {
  display: inline;
}

.payment-logo {
  max-width: 60px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #7b1fa2;
  background-color: green;
}

.vertical-center {
  margin: auto 0;
}

.radio-btn {
  text-align: center;
  margin-bottom: 50%;
}

.btn-fabel {
  background-color: #1e1e40;
  border: none;
  border-radius: 8px;
}
.btn-fabel:active {
  background-color: #1e1e40;
  border: none;
}
.btn-fabel:focus {
  background-color: #1e1e40;
  border: none;
}
.btn-fabel:hover {
  background-color: #313163;
}

.btn-success {
  background-color: #2db96b;
  border: none;
}

.btn-success:active {
  background-color: #2db96b;
  border: none;
}

.btn-success:hover {
  background-color: #247a4a;
}

.expandtransformation {
  transform: rotate(0.25turn);
  transition-duration: 0.5s;
}

.explanatory-text {
  margin-left: 20px;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.explanatory-text-small {
  margin-left: 20px;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.stripe-text {
  margin: 0 auto;
  margin-left: 20px;
}

.card-alert {
  width: 100%;
  margin: 0 auto;
  line-height: 6px;
  border-radius: 8px;
}
.card-alert {
  animation: fadeIn ease 6s;
  -webkit-animation: fadeIn ease 6s;
  -moz-animation: fadeIn ease 6s;
  -o-animation: fadeIn ease 6s;
  -ms-animation: fadeIn ease 6s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.strex-title {
  margin-left: 10px;
}

.strex-text {
  margin-left: 10px;
}

.giftcard-input {
  max-width: 30%;
  display: inline;
  margin-right: 10%;
}

.giftcard-btn {
  width: 30%;
}

.giftcard-text {
  margin-left: 10px;
}

.text-align-center{
  text-align: center !important;
}
.terminate-sub-text {
  margin-left: 10px;
}

.confirm-name-btn {
  margin-top: 4px;
}

.link-underline {
  color: #1e1e40 !important;
  text-decoration: underline !important;
  padding-left: 2px;
  margin-bottom: 2px;
}

.sub-list2 {
  border: none;
}

.top-banner {
  height: 30px;
  background-color: #2db96b;
  width: 100%;
  padding-top: 3px;
  text-align: center;
  color: white;
}
.strikePrice {
  color: #6F6B6B;
}

.desktop-only{
  display: block;
}

.mobile-only{
  display: none;
}
@media only screen and (max-width: 740px) {
  .card-main {
    width: 95%;
  }
  .book-thumbnail {
    width: 125px;
  }
  .book-text {
    font-size: 15px;
  }
  .purchased-books-controller {
    width: 100%;
  }
  .desktop-only{
    display: none;
  }
  .mobile-only{
    display: block;
  }
  .product-info-special {
    
    border-top: 0px solid #CCC;
  }
}
