footer {
    margin-bottom: 0;
    position: absolute; 
    bottom: 0; 
    width: 100%;
  }
  .footer {
    padding: 2rem 0px 0px 0px;
    background-color: #1e1e40;
  }

  footer div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 30px;
  }
  footer div:nth-child(1) a {
    color: white;
  }

  footer div:nth-child(2) a img {
    width: 150px;
  }

  .fade {
    display: none;
  }