
@font-face {
    font-family: fabel_font_FilsonPro;
    src: local("fabel_font_FilsonPro"),
      url("fonts/fonts/fonts/FilsonPro/Mostardesign-FilsonProRegular.otf")
        format("opentype");
  }
  @font-face {
    font-family: Filson Pro Regular;
    src: local("Filson Pro Regular"),
      url("fonts/fonts/fonts/FilsonPro/Mostardesign-FilsonProRegular.otf")
        format("opentype");
  }
  @font-face {
    font-family: Filson Pro Black;
    src: local("Filson Pro Black"),
      url("fonts/fonts/fonts/FilsonPro/Mostardesign\ -\ FilsonProBlack.otf")
        format("opentype");
  }
  @font-face {
    font-family: Filson Pro Heavy;
    src: local("Filson Pro Heavy"),
      url("fonts/fonts/fonts/FilsonPro/Mostardesign-FilsonProHeavy.otf")
        format("opentype");
  }
  
  @font-face {
    font-family: Sofia Pro Regular;
    src: local("Sofia Pro Regular"),
      url("fonts/fonts/fonts/SofiaPro/Mostardesign-SofiaProRegular.otf")
        format("opentype");
  }

  p,
span,
a,
div,
button,
input {
  font-family: Filson Pro Regular;
}
h1,
h2,
h3 {
  color: #1E1E40;
  font-family: Filson Pro Heavy;
}


.fabel-input {
  border-radius: 8px;
}
